.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  &__header {
    margin: 32px 0 5px;
    height: 22px;
    width: 283px;
    color: #808080;
    font-family: Arial;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 23px;
    text-align: center;
  }

  &__message {
    width: 476px;
    margin: 0 0 10px;
    color: #878787;
    font-family: Arial;
    font-size: 15px;
    letter-spacing: 0;
    line-height: 20px;
    text-align: center;
  }

  &__button {
    margin-top: 20px;
    box-sizing: border-box;
    height: 31px;
    width: 81px;
    border: 1px solid #0079B4;
    border-radius: 4px;
    background-color: #0A88C5;
    cursor: pointer;
    color: #FFFFFF;
    font-family: Arial;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 18px;
  }
}

.Spinner {
  display: inline-block;
  margin-top: 15px;
  width: 40px;
  height: 40px;
  border: 3px solid #0A88C5;
  border-radius: 50%;
  border-right-color: #f0f0f0;
  animation: spinner-border .75s linear infinite;
}

@keyframes spinner-border {
  100% {
    transform: rotate(360deg);
  }
}
